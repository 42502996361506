<div class="container-fluid customwidth">
    <div class="row p-3 align-items-center">
        <div class="col-sm-4 mb-2 mb-sm-0 text-center">
            <img src="../assets/images/logo.jpg" alt="logo" width="200px" height="81px">
        </div>
        <div class="col-sm-8 text-center">
            <a href="https://www.ilnidodigabbiano.com/it" class="btn btn-outline-dark btn-lg customborder mr-3">Scopri di più</a>
            <a href="https://www.ilnidodigabbiano.com/en" class="btn btn-outline-dark btn-lg customborder ml-3">See more</a>
        </div>
    </div>
</div>
<carousel [isAnimated]="true" class="customwidth">
    <slide >
        <img src="../assets/images/piscina-small.jpg" class="d-md-none" alt="Ilnidodigabbiano-piscina-slide" style="display: block; width: 100%;">
        <img src="../assets/images/piscina.jpg" class="d-none d-md-block" alt="Ilnidodigabbiano-piscina-slide" style="width: 100%;">
    </slide>
    <slide class="">
        <img src="../assets/images/ilnido-small.jpg" class="d-md-none"  alt="Ilnidodigabbiano-slide" style="display: block; width: 100%;">
        <img src="../assets/images/ilnido.jpg" class="d-none d-md-block"  alt="Ilnidodigabbiano-slide" style=" width: 100%;">
    </slide>
    <slide>
        <img src="../assets/images/ilnido1-small.jpg" class="d-md-none" alt="Ilnidodigabbiano1-slide" style="display: block; width: 100%;">
        <img src="../assets/images/ilnido1.jpg" class="d-none d-md-block" alt="Ilnidodigabbiano1-slide" style=" width: 100%;">
    </slide>
    <slide>
        <img src="../assets/images/camera-small.jpg" class="d-md-none" alt="Ilnidodigabbiano-camera-slide" style="display: block; width: 100%;">
        <img src="../assets/images/camera.jpg" class="d-none d-md-block" alt="Ilnidodigabbiano-camera-slide" style=" width: 100%;">
    </slide>
</carousel>