import {Component} from '@angular/core';
import {CarouselComponent, CarouselConfig} from 'ngx-bootstrap/carousel';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    providers: [
        { provide: CarouselConfig, useValue: { interval: 3500, noPause: true, showIndicators: false } }
    ],
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor() {

    }
}
